
    import  { computed, defineComponent, ref, toRefs, watch, onMounted } from 'vue';
    import {  Form, Field } from "vee-validate";
    import { getModule } from 'vuex-module-decorators';
    import toCurrency from "@/core/filters/ToCurrency"
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import NewSaleReturnModule from "@/store/modules/salesreturn/modules/new-salereturn";
    import InfoSaleReturn from '@/models/salesreturn/infoSaleReturn';
    import SaleReturnDetails from '@/models/salesreturn/saleReturnDetails';
    import UpdSaleReturn from '@/models/salesreturn/UpdSaleReturn';
    import UpdSaleReturnDetails from "@/models/salesreturn/UpdSaleReturnDetails"
    import { ReturnSalesTypes } from "@/core/enums/returnsales-type"
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import { translate } from "@/core/plugins/i18n";
    import { useRouter } from "vue-router";

  export default defineComponent({
      components:{
        Form,
        Field
      },
      props: {
       isActionAdd: {
           type: Number,
           default: ReturnSalesTypes.CreateSaleReturn
       }
    },
      setup(props){
        //Propiedades
        const showForm = ref(false);
        const formSaleReturn = ref();
        const isSale = ref(false);
        const router = useRouter();
        const saleId = ref("");
        const quantity_aux = ref(0);
        const { isActionAdd } = toRefs(props);
        const NewSaleDetails = getModule(NewSaleReturnModule);
        const penalization = ref(0);
    
        //METODOS
        const submitForm = (form: any) => {            
            const infoSale = NewSaleDetails.getValuesForm as InfoSaleReturn;
            const productsReturnDetails = NewSaleDetails.getSaleReturnDetailsAdd;
            console.log(productsReturnDetails);

            let total = NewSaleDetails.getTotalSale;
            let subtotal = NewSaleDetails.getImportSubtotalDetails;
            let totalDetails = NewSaleDetails.getImportTotalDetails;
            let totalTaxDetails = NewSaleDetails.getImportTotalTaxDetails;
            // let returntotal = subtotal;
            // if(form.quantityporc > 0 && form.quantityporc < 100)
            // {
            //     let percentage = 100 - form.quantityporc;
            //     let totalPercent = (subtotal * percentage)/100;
            //     returntotal = subtotal - totalPercent;
            // }
            
            const formSaleReturn = {
                id: infoSale.id,
                saleId: infoSale.saleId != null ? infoSale.saleId : infoSale.id, // se tiene que cambiar a id
                retrurnStatus: 0,
                customerId: infoSale.customerId,
                addressId: infoSale.addressId,                
                //Total = importe total de pantalla
                total: subtotal,                
                //Returntotal = importetotal - penalización
                returnTotal: total,                
                //Subtotal = sumatoria detalles (Columna total de detalles)
                subTotal: totalDetails,                
                //tax  = Sumatoria de impuesto de todos los detalles
                tax: totalTaxDetails,                 
                observation: "This is a test",//infoDetails 
                saleReturnAt: infoSale.saleReturnAt, //No tiene info esta null
                //Porcentaje de devolución
                percentReturn: form.quantityporc, //Devolución al                
                //PenaltyReturn = penalización         
                penaltyReturn : discontSale.value,     //totalPercent        
            } as InfoSaleReturn    

            const formUpdSaleReturn = {
                id: infoSale.id,
                //Total = importe total de pantalla
                total: subtotal,
                //Subtotal = sumatoria detalles (Columna total de detalles)
                subTotal: totalDetails,//infoSale.subTotal,
                //tax  = Sumatoria de impuesto de todos los detalles
                tax: totalTaxDetails,  //infoSale.tax,  //Revisar con Adolfo  
                //Porcentaje de devolución
                percentReturn: form.quantityporc,
                //PenaltyReturn = penalización         
                penaltyReturn : discontSale.value,     //totalPercent      
                //Returntotal = importetotal - penalización
                returnTotal: total,   
                deliveryStatus: 0,                    
                observation: infoSale.observation,
            } as UpdSaleReturn

            if(isActionAdd.value == ReturnSalesTypes.CreateSaleReturn){
                var saleReturnDetails = [] as SaleReturnDetails[];

                productsReturnDetails.forEach(item => {
                    if(item.status)
                    {
                        const productSaleReturn = {
                            //id: item.id ? item.id : "",
                            saleReturnId: item.saleReturnId ? item.saleReturnId : "00000000-0000-0000-0000-000000000000",
                            saleId: item.saleId,
                            //saleDetailId: item.id, //No se tiene info
                            productId: item.productId,
                            //productName: item.productName,
                            unitOfMeasurementId: item.unitOfMeasurementId, //No se tiene info
                            
                            price: item.price,
                            tax: item.tax,
                            amount: item.amount, 
                            subTotal:item.subTotal,
                            total:item.total,
                            taxPersent: item.taxPersent,//0,                            
                            discount: item.discount,
                            discountPersent: item.discountPersent,                     
                            
                            //status: item ? item.status : true,
                        } as SaleReturnDetails
                        saleReturnDetails.push(productSaleReturn);
                    }
                }); 
                formSaleReturn.saleReturnDetails = saleReturnDetails;
            }else{
                var UpdsaleReturnDetails = [] as UpdSaleReturnDetails[];

                productsReturnDetails.forEach(item => {
                    if(item.status)
                    {
                        const productSaleReturn = {
                            id: item.id ? item.id : "00000000-0000-0000-0000-000000000000",
                            saleId: item.saleId,
                            productId: item.productId,
                            unitOfMeasurementId: item.unitOfMeasurementId, //No se tiene info
                            
                            price: item.price,
                            discount: item.discount,
                            tax: item.tax,
                            amount: item.amount, 
                            subTotal:item.subTotal,
                            total:item.total,
                            taxPersent: item.taxPersent,
                            discountPersent: item.discountPersent,

                            status: item ? item.status : true,
                        } as UpdSaleReturnDetails
                        UpdsaleReturnDetails.push(productSaleReturn);
                    }
                }); 
                formUpdSaleReturn.SaleReturnDetails = UpdsaleReturnDetails;
            }            

            Swal.fire({
                    title: `¿Está seguro de crear la devolución del producto?`,
                    text: `Se procederá a crear la devolución con el producto seleccionado`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#ffb300',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, crear',
                    cancelButtonText: 'Cancelar'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(isActionAdd.value == ReturnSalesTypes.CreateSaleReturn){
                            sendCreateSaleReturn(formSaleReturn);
                        }else{
                            sendUpdateSaleReturn(formUpdSaleReturn);
                        }                        
                    }
                });

        }

        const sendCreateSaleReturn = async (form: InfoSaleReturn) => {
           const response = await NewSaleDetails.SAVE_NEW_SALERETURN(form) ;
            formSaleReturn.value.resetForm();
            NewSaleDetails.RESET_SALERETURN();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESRETURNS'), [translate('BREADCRUMB.MODULES')]);
            router.back(); 
        }

        const sendUpdateSaleReturn = async (form: UpdSaleReturn) => {
           const response = await NewSaleDetails.UPD_SALERETURN(form);
            formSaleReturn.value.resetForm();
            NewSaleDetails.RESET_SALERETURN();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESRETURNS'), [translate('BREADCRUMB.MODULES')]);
            router.back(); 
        }

        const handlerupdporcentage = (evt: number) => {
            let total = NewSaleDetails.getTotalSale;
            let returntotal = 0;
            if(evt > 0 && evt < 100){
                let percentage = 100 - evt;
                returntotal = (total * percentage)/100;
                total = total - returntotal;
                penalization.value = evt;
            }
            NewSaleDetails.UPD_SALE_PENALIZATION(returntotal);
        }

        const readOnly = computed(() => {
            let readOption = true;

            if(typeof(isActionAdd.value) === "number"){
                if(isActionAdd.value != ReturnSalesTypes.ReadSaleReturn){
                    readOption = false;
                }
            }
            const quantityporc = NewSaleDetails.getPercentageReturn;
            return readOption;
        });

        const clientNameSale = computed(() => NewSaleDetails.getSaleClientName);
        const rfcSale = computed(() => NewSaleDetails.getSaleRFC);
        const importSale = computed(() => NewSaleDetails.getImportSubtotalDetails);//getImportSale);
        const discontSale = computed(() => NewSaleDetails.getPenalization);
        const totalSale = computed(() => NewSaleDetails.getTotalSale);//getTotalSaleDetail);
        
        watch(() => NewSaleDetails.getPercentageReturn, (currentValue:number) => {//para usar el watch hay que hacerlo con base a un getter
            if(currentValue){
                formSaleReturn.value.setValues({ quantityporc: currentValue });
           }else{
                formSaleReturn.value.setValues({quantityporc: 100});
            }            
        });

        onMounted(() => {
            const quantityporc = NewSaleDetails.getPercentageReturn;
            if(quantityporc){
                formSaleReturn.value.setValues({ quantityporc: quantityporc });
            }
        });
        
        return {
           submitForm
           , clientNameSale
           , rfcSale
           , importSale
           , totalSale
           , discontSale
           , formSaleReturn
           , isSale
           , saleId
           , toCurrency
           , ReturnSalesTypes
           , handlerupdporcentage
           , readOnly
         }
      }
  })
  